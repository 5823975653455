<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">数据统计</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">企业合同查询</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items: flex-start">
                    <div class="searchbox" style="margin-bottom:15px">
                        <div title="企业名称" class="searchboxItem ci-full">
                            <span class="itemLabel">企业名称:</span>
                            <el-select size="small" v-model="searchData.compId" remote :remote-method="getCompanyList"
                                filterable clearable placeholder="请至少输入两个字搜索">
                                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                                    :value="item.compId" clearable></el-option>
                            </el-select>
                        </div>
                        <div title="机构有无盖章" class="searchboxItem ci-full">
                            <span class="itemLabel">机构有无盖章:</span>
                            <el-select size="small" v-model="searchData.contractsealState" placeholder="请选择机构有无盖章" clearable>
                                <el-option v-for="item in ywChapter" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div title="是否已经开班" class="searchboxItem ci-full">
                            <span class="itemLabel" style="width: 8rem;">是否已经开班:</span>
                            <el-select size="small" v-model="searchData.isOpenProject" placeholder="请选择是否已经开班" clearable>
                                <el-option v-for="item in openClass" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="df" style="padding-left:10px">
                            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small"
                            tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="协议名称" align="left" show-overflow-tooltip prop="contractName"
                                min-width="150px" />
                            <el-table-column label="协议形式" align="left" show-overflow-tooltip prop="contractForm"
                                min-width="100px">
                                <template slot-scope="scope">
                                    {{ $setDictionary("CONTRACT_FORM", scope.row.contractForm) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="企业名称" align="left" show-overflow-tooltip prop="compName"
                                min-width="180px" />
                            <el-table-column label="有效期" align="left" show-overflow-tooltip prop="" min-width="200px">
                                <template slot-scope="scope">
                                    {{ scope.row.startDate | momentDate }}至{{ scope.row.endDate | momentDate }}
                                </template>
                            </el-table-column>
                            <el-table-column label="机构有无盖章" align="left" show-overflow-tooltip prop="contractsealState"
                                min-width="120px">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractsealState == '10'">已盖章</span>
                                    <span v-if="scope.row.contractsealState == '20'">未盖章</span>
                                    <!-- {{ $setDictionary("CONTRACT_SEAL_STATE", scope.row.contractsealState) }} -->
                                </template>
                            </el-table-column>
                            <el-table-column label="纸质合同有无回收" align="left" show-overflow-tooltip prop="isTakeBack"
                                min-width="120px">
                                <template slot-scope="scope">
                                    {{ scope.row.isTakeBack ? '已回收' : '未回收' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="是否已经开班" align="left" show-overflow-tooltip prop="openProjectNum"
                                min-width="120px">
                                <template slot-scope="scope">
                                    {{ scope.row.openProjectNum > 0 ? '已经开' + scope.row.openProjectNum + '个班' : '未开班' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark" min-width="200px" />
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "settlement_enterpriseContractQuery",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            //查询条件
            searchData: {
                compId: "",//企业名称
                contractsealState: "",//机构是否盖章
                isOpenProject: "",//是否开班
            },
            // 企业名称
            companyList: [],
            // 机构有无盖章
            ywChapter: [],
            //是否开班
            openClass: [
                {
                    label: "是",
                    value: true,
                },
                {
                    label: "否",
                    value: false,
                },
            ],
        };
    },
    watch: {},
    created() {
        this.getDictionaryData();
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        // 获取 - 所属机构
        getCompanyList(query) {
            if (query.trim().length >= 2) {
                this.$post("/sys/company/queryCompanyList", { compName: query })
                    .then((res) => {
                        if (res.status == 0) {
                            this.companyList = res.data || [];
                        }
                    })
                    .catch(() => {
                        return;
                    });
            } else {
                this.companyList = [];
            }
        },
        //获取字典
        getDictionaryData() {
            // 机构有无盖章
            const arr1 = this.$setDictionary("CONTRACT_SEAL_STATE", "list");
            const list = [];
            for (const key in arr1) {
                if(key == '10'){
                    list.push({
                    value: key,
                    label: '已盖章',
                });
                }
                if(key == '20'){
                    list.push({
                    value: key,
                    label: '未盖章',
                });
                }
                
            }
            this.ywChapter = list;
        },
        // 获取列表数据
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                chargeUp: this.searchData.chargeUp,
                withBill: this.searchData.withBill,
                withImg: this.searchData.withImg,
            };
            if (this.searchData.compId) {//企业名称
                params.compId = this.searchData.compId;
            }
            if (this.searchData.contractsealState) {//机构是否盖章
                params.contractsealState = this.searchData.contractsealState;
            }
            if (typeof this.searchData.isOpenProject !='string') {//是否开班
                params.isOpenProject = this.searchData.isOpenProject;
            }
            this.doFetch({
                url: "/biz/new/bill/contract/compContractList",
                params,
                pageNum,
            });
        },
    },
};
</script>
<style lang="less" scoped></style>
